import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import NotFound from './pages/not-found';
import { Provider } from 'react-redux';
import { store, persistor } from './services/storage/configure-store';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from './components/basic/Loading';

import 'react-form-builder2/dist/app.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loading isLoading={true} message='Loading user data...' />} persistor={persistor}>
        <RouterProvider router={router} fallbackElement={<NotFound />} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
