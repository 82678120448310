import { configureStore } from '@reduxjs/toolkit';
import { customerReducer, userReducer } from './actions';
import { persistStore } from 'redux-persist';

export const store = configureStore({
  reducer: {
    user: userReducer,
    customer: customerReducer
  }
}); 

export const persistor = persistStore(store);
