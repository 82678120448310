import React from 'react';
import { Col } from 'react-bootstrap';

interface Props {
  isLoading: boolean;
  message?: string;
}

const Loading = (props: Props) => {

  if (props.isLoading) {
    return <Col className='text-center'>
      <i className="fa fa-spinner fa-spin fa-lg"></i>
      <p>{props.message}</p>
    </Col>;
  } else {
    return <React.Fragment></React.Fragment>;
  }

};

export default Loading;
