import { createSlice } from "@reduxjs/toolkit";
import User from "../../common/types/User";
import Customer from "../../common/types/Customer";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null
  } as { user: User | null; },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    }
  }
});
export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: null,
  } as { customer: Customer | null; },
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    removeCustomer: (state) => {
      state.customer = null;
    }
  }
});

export const { login, logout } = userSlice.actions;
export const { setCustomer, removeCustomer } = customerSlice.actions;

export const userReducer = persistReducer({
  key: "user",
  storage: storage
}, userSlice.reducer);
export const customerReducer = persistReducer({
  key: "customer",
  storage: storage,
}, customerSlice.reducer);
