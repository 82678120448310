import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import loadable from '@loadable/component';

const Login = loadable(() => import('../pages/login'));
const ProcurementPlanOffice = loadable(() => import('../pages/customer/procurement-plan-landing'));
const Logout = loadable(() => import('../pages/logout'));
const ProcurementViewer = loadable(() => import('../pages/customer/procurement-viewer'));
const ConsolidatedProcurementPlan = loadable(() => import('../pages/customer/consolidated-procurement-plan'));
const CommitteeSelection = loadable(() => import('../pages/customer/committee-selection'));
const UserTasks = loadable(() => import('../pages/customer/user-tasks'));
const DocumentCreation = loadable(() => import('../pages/customer/document-creation'));
const FormTest = loadable(() => import('../pages/form-test'));
const TemplateBuilder = loadable(() => import('../pages/customer/template-builder'));
const TemplateManagement = loadable(() => import('../pages/customer/template-management'));
const CreateRequestForProcurement = loadable(() => import('../pages/customer/procurement-flow/create-request-for-procurement'));


const router = createBrowserRouter([
  { path: '/', element: <Login /> },
  { path: '/login', element: <Login /> },
  { path: '/logout', element: <Logout /> },
  { path: '/:customerUid/procurement-plan/office', element: <ProcurementPlanOffice /> },
  { path: '/:customerUid/procurement-plan/:procurementPlanUid', element: <ProcurementViewer /> },
  { path: '/:customerUid/procurement-plan/consolidated', element: <ConsolidatedProcurementPlan /> },
  { path: '/:customerUid/committee', element: <CommitteeSelection /> },
  { path: '/:customerUid/documentation-creation/:procurementUid/:documentType', element: <DocumentCreation /> },

  /** template builder works either with an exact document ID or without a document (assuming new document) */
  { path: '/:customerUid/template-builder/', element: <TemplateBuilder /> },
  { path: '/:customerUid/template-builder/:documentId', element: <TemplateBuilder /> },
  { path: '/:customerUid/template-management', element: <TemplateManagement /> },

  /* User Pages (token is used for the user ID, not the url for security) */
  { path: '/user/tasks', element: <UserTasks /> },

  /* test page for the form builder and generator */
  { path: '/form-builder', element: <FormTest /> },

  /** procurement flow pages */
  { path: '/:customerUid/procurement-plan/create', element: <CreateRequestForProcurement /> },
]);

export default router;
